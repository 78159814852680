var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cards__wrapper", attrs: { id: "cards" } }, [
    _c("header", { staticClass: "tabs u-m-b4 u-m-b0@s" }, [
      _vm.$root.user !== false
        ? _c(
            "h4",
            {
              class: { inactive: !_vm.feedActive },
              attrs: { "data-tour": "my-feed" },
              on: {
                click: function ($event) {
                  return _vm.selectTab("feed")
                },
              },
            },
            [_vm._v("\n            My Feed\n        ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "h4",
        {
          class: { inactive: !_vm.exploreActive },
          attrs: { "data-tour": "explore" },
          on: {
            click: function ($event) {
              return _vm.selectTab("explore")
            },
          },
        },
        [
          _c(
            "svg",
            {
              attrs: {
                width: "22",
                height: "22",
                viewBox: "0 0 22 22",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("circle", {
                attrs: {
                  cx: "11",
                  cy: "11",
                  r: "10.25",
                  stroke: "black",
                  "stroke-width": "1.5",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M5.34787 5.01246L13.0331 8.85508C13.0815 8.87927 13.1207 8.9185 13.1449 8.96689L16.9875 16.6521C17.095 16.867 16.867 17.095 16.6521 16.9875L8.96689 13.1449C8.9185 13.1207 8.87927 13.0815 8.85508 13.0331L5.01246 5.34787C4.90502 5.13298 5.13298 4.90502 5.34787 5.01246Z",
                  stroke: "black",
                  "stroke-width": "1.5",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M9 13L13 9",
                  stroke: "black",
                  "stroke-width": "1.5",
                },
              }),
            ]
          ),
          _vm._v("\n            Explore\n        "),
        ]
      ),
    ]),
    _vm._v(" "),
    _vm.$root.user !== false && _vm.feedActive
      ? _c(
          "div",
          { staticClass: "home__tabs tab-feed" },
          [
            _c("HomeTab", {
              key: 1,
              attrs: { name: "feed", active: _vm.feedActive },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.exploreActive
      ? _c(
          "div",
          { staticClass: "home__tabs tab-explore" },
          [
            _c("HomeTab", {
              key: 2,
              attrs: { name: "explore", active: _vm.exploreActive },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }