var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cards" },
    [
      _vm.type === "feed" &&
      _vm.cards.length === 0 &&
      _vm.showPlaceholders === false
        ? _c("div", { staticClass: "u-width-full" }, [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "grid-item" }),
      _vm._v(" "),
      _vm.showPlaceholders ? _c("content-placeholders") : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.cardsLazy, function (card) {
        return _c("post-card-api", { key: card.id, attrs: { card: card } })
      }),
      _vm._v(" "),
      _vm._l(8, function (index) {
        return _vm.showPagingPlaceHolders
          ? [_c("content-placeholder")]
          : _vm._e()
      }),
      _vm._v(" "),
      _c("div", { staticStyle: { position: "absolute", bottom: "0" } }, [
        _vm._v(" "),
      ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "c-empty-state" }, [
      _c("h4", [_vm._v("Become a member of your favorite communities")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Once you are a member of a community relevant content will appear. Click on the Find a Community icon below to find and then join the communities that interest you."
        ),
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "c-empty-state__community_select u-m-t3",
          attrs: { href: "/communities" },
        },
        [
          _c("div", { staticClass: "c-communities-select__tile" }, [
            _c("img", {
              staticClass: "c-communities-select__img",
              attrs: {
                src: "/img/icons/icon-communities-squared--contained.svg",
                alt: "find communities",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "c-communities-select__name" }, [
              _c("p", [_vm._v("Find a Community")]),
            ]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }