var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "carousel", attrs: { "data-cy": "hot-communities" } },
    [
      _c(
        "VueSlickCarousel",
        _vm._b({}, "VueSlickCarousel", _vm.slickSettings, false),
        _vm._l(_vm.communities, function (item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "c-communities__tile c-communities__tile--hot",
            },
            [
              _c(
                "a",
                {
                  staticClass: "c-communities__tile-join",
                  attrs: { href: "#join" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.showJoinPanel(item.name)
                    },
                  },
                },
                [_vm._v("Join")]
              ),
              _vm._v(" "),
              _c("a", { attrs: { href: "/" + item.url } }, [
                item.imgUrl
                  ? _c("img", {
                      staticClass: "logo",
                      attrs: {
                        src: _vm.$simpleStore.cloudinaryUrl(
                          132,
                          132,
                          "img/communities/" +
                            item.communityId +
                            "/" +
                            item.imgUrl,
                          _vm.$root
                        ),
                        alt: "",
                        width: "132",
                        height: "132",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "name" }, [_vm._v(_vm._s(item.name))]),
              ]),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "c-btn new u-m-t4 u-m-b2 u-text-small",
          attrs: { href: "/hot" },
        },
        [_vm._v("Explore more")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }