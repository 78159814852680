<template>
    <div class="cards__wrapper" id="cards" v-cloak>
        <header class="tabs u-m-b4 u-m-b0@s">
            <h4
                v-if="$root.user !== false"
                @click="selectTab('feed')"
                :class="{ inactive: !feedActive }"
                data-tour="my-feed"
            >
                My Feed
            </h4>
            <h4
                @click="selectTab('explore')"
                :class="{ inactive: !exploreActive }"
                data-tour="explore"
            >
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="11" cy="11" r="10.25" stroke="black" stroke-width="1.5"/>
                    <path
                        d="M5.34787 5.01246L13.0331 8.85508C13.0815 8.87927 13.1207 8.9185 13.1449 8.96689L16.9875 16.6521C17.095 16.867 16.867 17.095 16.6521 16.9875L8.96689 13.1449C8.9185 13.1207 8.87927 13.0815 8.85508 13.0331L5.01246 5.34787C4.90502 5.13298 5.13298 4.90502 5.34787 5.01246Z"
                        stroke="black"
                        stroke-width="1.5"
                    />
                    <path d="M9 13L13 9" stroke="black" stroke-width="1.5" />
                </svg>
                Explore
            </h4>
        </header>
        <div
            v-if="$root.user !== false && feedActive"
            class="home__tabs tab-feed"
        >
            <HomeTab name="feed" :active="feedActive" :key="1"></HomeTab>
        </div>
        <div v-if="exploreActive" class="home__tabs tab-explore">
            <HomeTab name="explore" :active="exploreActive" :key="2"></HomeTab>
        </div>
    </div>
</template>

<script>
import HomeTab from "./HomeTab.vue";
export default {
    components: {
        HomeTab,
    },
    props: ["joinedcommunities"],
    data() {
        return {
            exploreActive:
                this.$root.user === false || this.joinedcommunities === 0,
            feedActive: this.$root.user !== false && this.joinedcommunities > 0,
            numCommunities: this.joinedcommunities,
        };
    },
    created() {
        this.$root.$on('bus-tab-explore', (data) => {
            this.selectTab('explore');
        });
        this.$root.$on('bus-tab-feed', (data) => {
            this.selectTab('feed');
        });
        /*let activeTab;
        if (this.$root.user === false) {
            activeTab = 'explore';
        } else {
            activeTab = sessionStorage.getItem('activeTab');
        }
        if (activeTab != null) {
            this.selectTab(activeTab);
        }*/

        if (this.$route.path === '/explore') {
            this.selectTab('explore');
        }
    },
    methods: {
        selectTab(tab) {
            if (tab === "explore") {
                this.exploreActive = true;
                this.feedActive = false;
                history.pushState({}, '', '/explore');
                this.$root.$emit('bus-nav-explore');
            } else {
                this.feedActive = true;
                this.exploreActive = false;
                history.pushState({}, '', '/');
                this.$root.$emit('bus-nav-feed');
            }
            sessionStorage.setItem("activeTab", tab);
        },
    },
};
</script>
