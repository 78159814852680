var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a", { attrs: { href: "/" + _vm.community.url } }, [
    _c("img", {
      staticClass: "image",
      attrs: {
        src:
          "https://res.cloudinary.com/dyadey/image/upload/c_fill,f_auto,q_auto,g_center,dpr_1.0,w_" +
          _vm.width +
          ",h_" +
          _vm.height +
          "/v1/i/" +
          _vm.community.thumb,
        alt: "",
      },
    }),
    _vm._v(" "),
    _c("img", {
      staticClass: "logo",
      attrs: {
        src: _vm.$simpleStore.cloudinaryUrl(
          _vm.logoDims,
          _vm.logoDims,
          "img/communities/" +
            _vm.community.community_id +
            "/" +
            _vm.community.image,
          _vm.$root
        ),
        alt: "",
        width: _vm.logoDims,
        height: _vm.logoDims,
      },
    }),
    _vm._v(" "),
    _c("div", {
      staticClass: "name",
      class: ["name", _vm.height >= _vm.width ? "portrait" : ""],
      domProps: { innerHTML: _vm._s(_vm.community.name) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }