<template>
    <div>
        <main class="page home" role="main">
            <!-- mobile in portrait view -->
            <div v-if="$root.user === false && (mediaSize === 'xs' || mediaSize === 's')"
                class="hero hero__mobile-portrait"
            >
                <div class="cta">
                    <h1 v-twitteremoji>
                        EVERYTHING<br>YOU ❤️ LOVE<br>IN ONE PLACE
                    </h1>
                </div>
                <div class="top">
                    <div class="box box__74x103">
                        <HeroCommunity :width="74" :height="103" :logoDims="31" :reset="true"></HeroCommunity>
                    </div>
                    <div class="box box__103x74">
                        <HeroCommunity :width="103" :height="74" :logoDims="31"></HeroCommunity>
                    </div>
                    <div class="box box__86x86">
                        <HeroCommunity :width="86" :height="86" :logoDims="31"></HeroCommunity>
                    </div>
                    <div class="box box__103x74">
                        <HeroCommunity :width="103" :height="74" :logoDims="31"></HeroCommunity>
                    </div>
                    <div class="box box__74x103">
                        <HeroCommunity :width="74" :height="103" :logoDims="31"></HeroCommunity>
                    </div>
                </div>
                <div class="mid">
                    <div class="box box__86x86">
                        <HeroCommunity :width="86" :height="86" :logoDims="31"></HeroCommunity>
                    </div>
                    <div class="box box__103x74">
                        <HeroCommunity :width="103" :height="74" :logoDims="31"></HeroCommunity>
                    </div>
                    <div class="box box__74x103">
                        <HeroCommunity :width="74" :height="103" :logoDims="31"></HeroCommunity>
                    </div>
                    <div class="box box__74x103">
                        <HeroCommunity :width="74" :height="103" :logoDims="31"></HeroCommunity>
                    </div>
                    <div class="box box__103x74">
                        <HeroCommunity :width="103" :height="74" :logoDims="31"></HeroCommunity>
                    </div>
                    <div class="box box__86x86">
                        <HeroCommunity :width="86" :height="86" :logoDims="31"></HeroCommunity>
                    </div>
                </div>
                <div class="end">
                    <div class="box box__103x103">
                        <HeroCommunity :width="103" :height="103" :logoDims="31"></HeroCommunity>
                    </div>
                    <div class="box box__103x74">
                        <HeroCommunity :width="103" :height="74" :logoDims="31"></HeroCommunity>
                    </div>
                    <div class="box box__160x74">
                        <HeroCommunity :width="160" :height="74" :logoDims="31"></HeroCommunity>
                    </div>
                    <div class="box box__103x74">
                        <HeroCommunity :width="103" :height="74" :logoDims="31"></HeroCommunity>
                    </div>
                    <div class="box box__103x103">
                        <HeroCommunity :width="103" :height="103" :logoDims="31"></HeroCommunity>
                    </div>
                </div>
                <div class="cta">
                    <h2>All socials feeds & content,<br>on one platform</h2>
                    <button class="c-btn new u-m-t4 u-m-b2" @click.prevent="showSignup">
                        Create Account
                    </button>
                </div>
            </div>
            <!-- tablet view -->
            <div
                v-else-if="$root.user === false && mediaSize !== 'xl'"
                class="hero hero__mobile"
            >
                <div class="cta">
                    <h1 v-twitteremoji>
                        EVERYTHING<br>YOU ❤️ LOVE<br>IN ONE PLACE
                    </h1>
                </div>
                <div class="top">
                    <div class="box box__100x138">
                        <HeroCommunity :width="100" :height="138" :logoDims="42" :reset="true"></HeroCommunity>
                    </div>
                    <div class="box box__138x100">
                        <HeroCommunity :width="138" :height="100" :logoDims="42"></HeroCommunity>
                    </div>
                    <div class="box box__116x116">
                        <HeroCommunity :width="116" :height="116" :logoDims="42"></HeroCommunity>
                    </div>
                    <div class="box box__138x100">
                        <HeroCommunity :width="138" :height="100" :logoDims="42"></HeroCommunity>
                    </div>
                    <div class="box box__100x138">
                        <HeroCommunity :width="100" :height="138" :logoDims="42"></HeroCommunity>
                    </div>
                </div>
                <div class="mid">
                    <div class="box box__116x116">
                        <HeroCommunity :width="116" :height="116" :logoDims="42"></HeroCommunity>
                    </div>
                    <div class="box box__138x100">
                        <HeroCommunity :width="138" :height="100" :logoDims="42"></HeroCommunity>
                    </div>
                    <div class="box box__100x138">
                        <HeroCommunity :width="100" :height="138" :logoDims="42"></HeroCommunity>
                    </div>
                    <div class="box box__100x138">
                        <HeroCommunity :width="100" :height="138" :logoDims="42"></HeroCommunity>
                    </div>
                    <div class="box box__138x100">
                        <HeroCommunity :width="138" :height="100" :logoDims="42"></HeroCommunity>
                    </div>
                    <div class="box box__116x116">
                        <HeroCommunity :width="116" :height="116" :logoDims="42"></HeroCommunity>
                    </div>
                </div>
                <div class="end">
                    <div class="box box__138x138">
                        <HeroCommunity :width="138" :height="138" :logoDims="42"></HeroCommunity>
                    </div>
                    <div class="box box__138x100">
                        <HeroCommunity :width="138" :height="100" :logoDims="42"></HeroCommunity>
                    </div>
                    <div class="box box__215x100">
                        <HeroCommunity :width="215" :height="100" :logoDims="42"></HeroCommunity>
                    </div>
                    <div class="box box__138x100">
                        <HeroCommunity :width="138" :height="100" :logoDims="42"></HeroCommunity>
                    </div>
                    <div class="box box__138x138">
                        <HeroCommunity :width="138" :height="138" :logoDims="42"></HeroCommunity>
                    </div>
                </div>
                <div class="cta">
                    <h2>All socials feeds & content,<br>on one platform</h2>
                    <button class="c-btn new u-m-t4 u-m-b2" @click.prevent="showSignup">
                        Create Account
                    </button>
                </div>
            </div>
            <!-- desktop view -->
            <div
                v-else-if="$root.user === false && mediaSize === 'xl'"
                class="hero hero__desktop"
            >
                <div class="top">
                    <div class="item g1">
                        <div class="box box__130x180">
                            <HeroCommunity :width="130" :height="180" :reset="true"></HeroCommunity>
                        </div>
                        <div class="box box__180x130">
                            <HeroCommunity :width="180" :height="130"></HeroCommunity>
                        </div>
                        <div class="box box__130x180">
                            <HeroCommunity :width="130" :height="180"></HeroCommunity>
                        </div>
                    </div>
                    <div class="item g2">
                        <div class="box box__180x130">
                            <HeroCommunity :width="180" :height="130"></HeroCommunity>
                        </div>
                        <div class="box box__150x150">
                            <HeroCommunity :width="150" :height="150"></HeroCommunity>
                        </div>
                        <div class="box box__180x130">
                            <HeroCommunity :width="180" :height="130"></HeroCommunity>
                        </div>
                    </div>
                    <div class="item g3">
                        <div class="box box__130x180">
                            <HeroCommunity :width="130" :height="180"></HeroCommunity>
                        </div>
                        <div class="box box__180x130">
                            <HeroCommunity :width="180" :height="130"></HeroCommunity>
                        </div>
                        <div class="box box__130x180">
                            <HeroCommunity :width="130" :height="180"></HeroCommunity>
                        </div>
                    </div>
                </div>
                <div class="mid">
                    <div class="item g1">
                        <div class="box box__150x150">
                            <HeroCommunity :width="150" :height="150"></HeroCommunity>
                        </div>
                        <div class="box box__180x150">
                            <HeroCommunity :width="180" :height="150"></HeroCommunity>
                        </div>
                        <div class="box box__150x150">
                            <HeroCommunity :width="150" :height="150"></HeroCommunity>
                        </div>
                    </div>
                    <div class="item g2">
                        <div class="cta">
                            <h1 v-twitteremoji>
                                EVERYTHING<br>YOU ❤️ LOVE<br>IN ONE PLACE
                            </h1>
                            <h3 class="cta__subtitle">
                                All social feeds & fan content, on one platform.
                            </h3>
                            <div class="search">
                                <input
                                    type="search"
                                    name="search"
                                    id="search"
                                    placeholder="Search what you love..."
                                />
                            </div>
                            <button class="c-btn new u-m-t5 u-m-b2" @click.prevent="showSignup">
                                Create Account
                            </button>
                        </div>
                    </div>
                    <div class="item g3">
                        <div class="box box__150x150">
                            <HeroCommunity :width="150" :height="150"></HeroCommunity>
                        </div>
                        <div class="box box__180x150">
                            <HeroCommunity :width="180" :height="150"></HeroCommunity>
                        </div>
                        <div class="box box__150x150">
                            <HeroCommunity :width="150" :height="150"></HeroCommunity>
                        </div>
                    </div>
                </div>
                <div class="end">
                    <div class="item g1">
                        <div class="box box__180x130">
                            <HeroCommunity :width="180" :height="130"></HeroCommunity>
                        </div>
                        <div class="box box__130x180">
                            <HeroCommunity :width="130" :height="180"></HeroCommunity>
                        </div>
                        <div class="box box__180x130">
                            <HeroCommunity :width="180" :height="130"></HeroCommunity>
                        </div>
                    </div>
                    <div class="item g2"></div>
                    <div class="item g3">
                        <div class="box box__180x130">
                            <HeroCommunity :width="180" :height="130"></HeroCommunity>
                        </div>
                        <div class="box box__130x180">
                            <HeroCommunity :width="130" :height="180"></HeroCommunity>
                        </div>
                        <div class="box box__180x130">
                            <HeroCommunity :width="180" :height="130"></HeroCommunity>
                        </div>
                    </div>
                </div>
            </div>
            <!--                        <h1>EVERYTHING<br>YOU LOVE<br>IN ONE PLACE</h1>
                <div>
                    <a v-cloak v-if="$cookies.get('member') === '1'" href="#" class="c-btn u-m-r2" @click.prevent="showLogin">Sign In</a>
                    <a v-cloak v-else href="#" class="c-btn u-m-r2" @click.prevent="showSignup">Create Account</a>
                </div>-->
            <div v-else class="hero hero__desktop hero__desktop__logged-in">
                <div class="top">
                    <div
                        v-if="mediaSize === 'xl' || mediaSize === 'l'"
                        class="item g1"
                    >
                        <div class="box box__112x155">
                            <HeroCommunity :width="112" :height="155" :reset="true"></HeroCommunity>
                        </div>
                        <div class="box box__155x112">
                            <HeroCommunity :width="155" :height="112"></HeroCommunity>
                        </div>
                        <div class="box box__112x155">
                            <HeroCommunity :width="112" :height="155"></HeroCommunity>
                        </div>
                        <div class="box box__155x112">
                            <HeroCommunity :width="155" :height="112"></HeroCommunity>
                        </div>
                        <div class="box box__112x155">
                            <HeroCommunity :width="112" :height="155"></HeroCommunity>
                        </div>
                        <div class="box box__155x112">
                            <HeroCommunity :width="155" :height="112"></HeroCommunity>
                        </div>
                        <div class="box box__112x155">
                            <HeroCommunity :width="112" :height="155"></HeroCommunity>
                        </div>
                        <div class="box box__155x112">
                            <HeroCommunity :width="155" :height="112"></HeroCommunity>
                        </div>
                    </div>
                    <div class="item g2">
                        <div class="cta">
                            <h1 v-twitteremoji>👋 HI {{$root.user.firstName}}</h1>
                        </div>
                    </div>
                    <div
                        v-if="mediaSize === 'xl' || mediaSize === 'l'"
                        class="item g3"
                    >
                        <div class="box box__112x155">
                            <HeroCommunity :width="112" :height="155"></HeroCommunity>
                        </div>
                        <div class="box box__155x112">
                            <HeroCommunity :width="155" :height="112"></HeroCommunity>
                        </div>
                        <div class="box box__112x155">
                            <HeroCommunity :width="112" :height="155"></HeroCommunity>
                        </div>
                        <div class="box box__155x112">
                            <HeroCommunity :width="155" :height="112"></HeroCommunity>
                        </div>
                        <div class="box box__112x155">
                            <HeroCommunity :width="112" :height="155"></HeroCommunity>
                        </div>
                        <div class="box box__155x112">
                            <HeroCommunity :width="155" :height="112"></HeroCommunity>
                        </div>
                        <div class="box box__112x155">
                            <HeroCommunity :width="112" :height="155"></HeroCommunity>
                        </div>
                        <div class="box box__155x112">
                            <HeroCommunity :width="155" :height="112"></HeroCommunity>
                        </div>
                    </div>
                    <div
                        v-if="mediaSize === 'm' || mediaSize === 's' || mediaSize === 'xs'"
                        class="item"
                    >
                        <div class="box box__74x103">
                            <HeroCommunity :width="74" :height="103" :logoDims="42" :reset="true"></HeroCommunity>
                        </div>
                        <div class="box box__103x74">
                            <HeroCommunity :width="103" :height="74" :logoDims="42"></HeroCommunity>
                        </div>
                        <div class="box box__74x103">
                            <HeroCommunity :width="74" :height="103" :logoDims="42"></HeroCommunity>
                        </div>
                        <div class="box box__103x74">
                            <HeroCommunity :width="103" :height="74" :logoDims="42"></HeroCommunity>
                        </div>
                        <div class="box box__74x103">
                            <HeroCommunity :width="74" :height="103" :logoDims="42"></HeroCommunity>
                        </div>
                        <div class="box box__103x74">
                            <HeroCommunity :width="103" :height="74" :logoDims="42"></HeroCommunity>
                        </div>
                        <div class="box box__74x103">
                            <HeroCommunity :width="74" :height="103" :logoDims="42"></HeroCommunity>
                        </div>
                        <div class="box box__103x74">
                            <HeroCommunity :width="103" :height="74" :logoDims="42"></HeroCommunity>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="$root.user === false" class="c-popular-communities">
                <div class="o-wrapper">
                    <div class="o-grid">
                        <div class="o-grid__item u-width-full">
                            <header class="">
                                <h4>
                                    <a v-if="$root.user !== false" href="/hot"
                                        >POPULAR COMMUNITIES</a
                                    >
                                    <a v-else href="/hot"
                                        >NEW COMMUNITIES FOR YOU</a
                                    >
                                </h4>
                            </header>
                            <div class="">
                                <router-view
                                    name="hotCommunities"
                                ></router-view>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="o-wrapper">
                <div class="o-grid">
                    <div class="o-grid__item u-width-full">
                        <router-view
                            name="homeTabs"
                            :joinedcommunities="communityMember"
                        ></router-view>
                    </div>
                </div>
            </div>
<!--            <a
                v-if="$root.user !== false"
                href="/post/create/"
                class="c-article__add"
            ></a>
            <a
                v-else
                @click.prevent="showMemberPanel"
                class="c-article__add"
            ></a>-->
        </main>
        <modal></modal>
        <preferences-modal :channel="registration_channel"></preferences-modal>
    </div>
</template>

<script>
import HeroCommunity from "./HeroCommunity.vue";
import Modal from "../Modal.vue";
import PreferencesModal from "../PreferencesModal.vue";
import TwitterEmoji from "@twemoji/api";

export default {
    components: {
        HeroCommunity,
        Modal,
        PreferencesModal,
    },
    data() {
        return {
            banners: this.$root.banners,
            communityMember: this.$root.communityMember,
            registration_channel:
                typeof this.$root.registration_channel != 'undefined'
                    ? this.$root.registration_channel
                    : null,
            hover: false,
            mediaSize: this.$simpleStore.getMediaSize(),
            tour: [
                {
                    target: 'communities',
                    header: {
                        title: 'Get Started',
                    },
                    content: `View list of communities`,
                    crossDevice: false,
                },
                {
                    target: 'profile',
                    content: `View your Profile`,
                    crossDevice: false,
                },
                {
                    target: 'notifications',
                    content: `Check your notifications`,
                    crossDevice: false,
                },
                {
                    target: 'search',
                    content: `Search for communities,<br>content and people`,
                    crossDevice: false,
                    /*params: {
                        // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
                        placement: 'left'
                    }*/
                },
                {
                    target: 'my-feed',
                    content:
                        `View latest posts from<br><a href='/u/` +
                        this.$root.user.username +
                        `' style='text-decoration: underline'>your communities</a>`,
                    params: {
                        enableScrolling: false,
                    },
                },
                {
                    target: 'explore',
                    content: `Check out the latest posts<br>trending in <a href='/settings/interests' style='text-decoration: underline'>your categories</a>`,
                    params: {
                        enableScrolling: false,
                    },
                },
            ],
        };
    },
    created() {
        this.$store.commit('startTour', this);
        window.addEventListener('resize', this.windowResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.windowResize);
    },
    mounted() {
        if (this.$root.invite != null && this.$root.invite === 'iu') {
            this.$simpleStore.showLoginPanel(this);
        }
        if (this.$root.invite != null && this.$root.invite === 'ig') {
            this.$simpleStore.showSignupPanel(this);
        }
    },
    computed: {
        /*homeBackground() {
            let mediaSize = this.$simpleStore.getMediaSize();
            let dims = 'w_1440,h_504';
            switch (mediaSize) {
                case 'xl':
                    dims = 'w_1440,h_504';
                    break;
                case 'l':
                    break;
                case 'm':
                    dims = 'w_1020,h_380';
                    dims = 'w_768,h_270';
                    break;
                case 's':
                    dims = 'w_479,h_230';
                    break;
                case 'xs':
                    dims = 'w_375,h_230';
                    break;
            }
            return (this.$root.cloudinaryUrl ? this.$root.cloudinaryUrl + 'f_auto,q_auto,c_fill,' + dims : '') + '/img/hero-home--out.jpg';
        },*/
        heroDimensions() {
            let mediaSize = this.$simpleStore.getMediaSize();
            console.log('mediaSize:', mediaSize);
            switch (mediaSize) {
                case 'xl':
                    return 'w_1440,h_504';
                case 'l':
                    return 'w_1020,h_380';
                case 'm':
                    return 'w_768,h_270';
                case 's':
                    return 'w_479,h_230';
                case 'xs':
                    return 'w_375,h_230';
            }
        },
    },
    methods: {
        showMemberPanel() {
            this.$simpleStore.showMemberPanel(this);
        },
        showLogin() {
            this.$simpleStore.showLoginPanel(this);
        },
        showSignup() {
            console.log('showSignup');
            this.$simpleStore.showSignupPanel(this);
        },
        windowResize() {
            this.mediaSize = this.$simpleStore.getMediaSize();
        },
    },
    directives: {
        twitteremoji: {
            inserted(el) {
                if (typeof TwitterEmoji === 'object' && typeof TwitterEmoji.parse === 'function') {
                    TwitterEmoji.parse(el, { folder: 'svg', ext: '.svg' });
                }
            },
        },
    },
};
</script>
