var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("main", { staticClass: "page home", attrs: { role: "main" } }, [
        _vm.$root.user === false &&
        (_vm.mediaSize === "xs" || _vm.mediaSize === "s")
          ? _c("div", { staticClass: "hero hero__mobile-portrait" }, [
              _c("div", { staticClass: "cta" }, [
                _c(
                  "h1",
                  {
                    directives: [
                      { name: "twitteremoji", rawName: "v-twitteremoji" },
                    ],
                  },
                  [
                    _vm._v("\n                        EVERYTHING"),
                    _c("br"),
                    _vm._v("YOU ❤️ LOVE"),
                    _c("br"),
                    _vm._v("IN ONE PLACE\n                    "),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "top" }, [
                _c(
                  "div",
                  { staticClass: "box box__74x103" },
                  [
                    _c("HeroCommunity", {
                      attrs: {
                        width: 74,
                        height: 103,
                        logoDims: 31,
                        reset: true,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box box__103x74" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 103, height: 74, logoDims: 31 },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box box__86x86" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 86, height: 86, logoDims: 31 },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box box__103x74" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 103, height: 74, logoDims: 31 },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box box__74x103" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 74, height: 103, logoDims: 31 },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mid" }, [
                _c(
                  "div",
                  { staticClass: "box box__86x86" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 86, height: 86, logoDims: 31 },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box box__103x74" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 103, height: 74, logoDims: 31 },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box box__74x103" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 74, height: 103, logoDims: 31 },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box box__74x103" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 74, height: 103, logoDims: 31 },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box box__103x74" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 103, height: 74, logoDims: 31 },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box box__86x86" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 86, height: 86, logoDims: 31 },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "end" }, [
                _c(
                  "div",
                  { staticClass: "box box__103x103" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 103, height: 103, logoDims: 31 },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box box__103x74" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 103, height: 74, logoDims: 31 },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box box__160x74" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 160, height: 74, logoDims: 31 },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box box__103x74" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 103, height: 74, logoDims: 31 },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box box__103x103" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 103, height: 103, logoDims: 31 },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cta" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "c-btn new u-m-t4 u-m-b2",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.showSignup.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        Create Account\n                    "
                    ),
                  ]
                ),
              ]),
            ])
          : _vm.$root.user === false && _vm.mediaSize !== "xl"
          ? _c("div", { staticClass: "hero hero__mobile" }, [
              _c("div", { staticClass: "cta" }, [
                _c(
                  "h1",
                  {
                    directives: [
                      { name: "twitteremoji", rawName: "v-twitteremoji" },
                    ],
                  },
                  [
                    _vm._v("\n                        EVERYTHING"),
                    _c("br"),
                    _vm._v("YOU ❤️ LOVE"),
                    _c("br"),
                    _vm._v("IN ONE PLACE\n                    "),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "top" }, [
                _c(
                  "div",
                  { staticClass: "box box__100x138" },
                  [
                    _c("HeroCommunity", {
                      attrs: {
                        width: 100,
                        height: 138,
                        logoDims: 42,
                        reset: true,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box box__138x100" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 138, height: 100, logoDims: 42 },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box box__116x116" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 116, height: 116, logoDims: 42 },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box box__138x100" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 138, height: 100, logoDims: 42 },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box box__100x138" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 100, height: 138, logoDims: 42 },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mid" }, [
                _c(
                  "div",
                  { staticClass: "box box__116x116" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 116, height: 116, logoDims: 42 },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box box__138x100" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 138, height: 100, logoDims: 42 },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box box__100x138" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 100, height: 138, logoDims: 42 },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box box__100x138" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 100, height: 138, logoDims: 42 },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box box__138x100" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 138, height: 100, logoDims: 42 },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box box__116x116" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 116, height: 116, logoDims: 42 },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "end" }, [
                _c(
                  "div",
                  { staticClass: "box box__138x138" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 138, height: 138, logoDims: 42 },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box box__138x100" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 138, height: 100, logoDims: 42 },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box box__215x100" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 215, height: 100, logoDims: 42 },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box box__138x100" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 138, height: 100, logoDims: 42 },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box box__138x138" },
                  [
                    _c("HeroCommunity", {
                      attrs: { width: 138, height: 138, logoDims: 42 },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cta" }, [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "c-btn new u-m-t4 u-m-b2",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.showSignup.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        Create Account\n                    "
                    ),
                  ]
                ),
              ]),
            ])
          : _vm.$root.user === false && _vm.mediaSize === "xl"
          ? _c("div", { staticClass: "hero hero__desktop" }, [
              _c("div", { staticClass: "top" }, [
                _c("div", { staticClass: "item g1" }, [
                  _c(
                    "div",
                    { staticClass: "box box__130x180" },
                    [
                      _c("HeroCommunity", {
                        attrs: { width: 130, height: 180, reset: true },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "box box__180x130" },
                    [
                      _c("HeroCommunity", {
                        attrs: { width: 180, height: 130 },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "box box__130x180" },
                    [
                      _c("HeroCommunity", {
                        attrs: { width: 130, height: 180 },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item g2" }, [
                  _c(
                    "div",
                    { staticClass: "box box__180x130" },
                    [
                      _c("HeroCommunity", {
                        attrs: { width: 180, height: 130 },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "box box__150x150" },
                    [
                      _c("HeroCommunity", {
                        attrs: { width: 150, height: 150 },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "box box__180x130" },
                    [
                      _c("HeroCommunity", {
                        attrs: { width: 180, height: 130 },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item g3" }, [
                  _c(
                    "div",
                    { staticClass: "box box__130x180" },
                    [
                      _c("HeroCommunity", {
                        attrs: { width: 130, height: 180 },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "box box__180x130" },
                    [
                      _c("HeroCommunity", {
                        attrs: { width: 180, height: 130 },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "box box__130x180" },
                    [
                      _c("HeroCommunity", {
                        attrs: { width: 130, height: 180 },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mid" }, [
                _c("div", { staticClass: "item g1" }, [
                  _c(
                    "div",
                    { staticClass: "box box__150x150" },
                    [
                      _c("HeroCommunity", {
                        attrs: { width: 150, height: 150 },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "box box__180x150" },
                    [
                      _c("HeroCommunity", {
                        attrs: { width: 180, height: 150 },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "box box__150x150" },
                    [
                      _c("HeroCommunity", {
                        attrs: { width: 150, height: 150 },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item g2" }, [
                  _c("div", { staticClass: "cta" }, [
                    _c(
                      "h1",
                      {
                        directives: [
                          { name: "twitteremoji", rawName: "v-twitteremoji" },
                        ],
                      },
                      [
                        _vm._v("\n                                EVERYTHING"),
                        _c("br"),
                        _vm._v("YOU ❤️ LOVE"),
                        _c("br"),
                        _vm._v("IN ONE PLACE\n                            "),
                      ]
                    ),
                    _vm._v(" "),
                    _c("h3", { staticClass: "cta__subtitle" }, [
                      _vm._v(
                        "\n                                All social feeds & fan content, on one platform.\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._m(2),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "c-btn new u-m-t5 u-m-b2",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.showSignup.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                                Create Account\n                            "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item g3" }, [
                  _c(
                    "div",
                    { staticClass: "box box__150x150" },
                    [
                      _c("HeroCommunity", {
                        attrs: { width: 150, height: 150 },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "box box__180x150" },
                    [
                      _c("HeroCommunity", {
                        attrs: { width: 180, height: 150 },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "box box__150x150" },
                    [
                      _c("HeroCommunity", {
                        attrs: { width: 150, height: 150 },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "end" }, [
                _c("div", { staticClass: "item g1" }, [
                  _c(
                    "div",
                    { staticClass: "box box__180x130" },
                    [
                      _c("HeroCommunity", {
                        attrs: { width: 180, height: 130 },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "box box__130x180" },
                    [
                      _c("HeroCommunity", {
                        attrs: { width: 130, height: 180 },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "box box__180x130" },
                    [
                      _c("HeroCommunity", {
                        attrs: { width: 180, height: 130 },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item g2" }),
                _vm._v(" "),
                _c("div", { staticClass: "item g3" }, [
                  _c(
                    "div",
                    { staticClass: "box box__180x130" },
                    [
                      _c("HeroCommunity", {
                        attrs: { width: 180, height: 130 },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "box box__130x180" },
                    [
                      _c("HeroCommunity", {
                        attrs: { width: 130, height: 180 },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "box box__180x130" },
                    [
                      _c("HeroCommunity", {
                        attrs: { width: 180, height: 130 },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ])
          : _c(
              "div",
              { staticClass: "hero hero__desktop hero__desktop__logged-in" },
              [
                _c("div", { staticClass: "top" }, [
                  _vm.mediaSize === "xl" || _vm.mediaSize === "l"
                    ? _c("div", { staticClass: "item g1" }, [
                        _c(
                          "div",
                          { staticClass: "box box__112x155" },
                          [
                            _c("HeroCommunity", {
                              attrs: { width: 112, height: 155, reset: true },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "box box__155x112" },
                          [
                            _c("HeroCommunity", {
                              attrs: { width: 155, height: 112 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "box box__112x155" },
                          [
                            _c("HeroCommunity", {
                              attrs: { width: 112, height: 155 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "box box__155x112" },
                          [
                            _c("HeroCommunity", {
                              attrs: { width: 155, height: 112 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "box box__112x155" },
                          [
                            _c("HeroCommunity", {
                              attrs: { width: 112, height: 155 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "box box__155x112" },
                          [
                            _c("HeroCommunity", {
                              attrs: { width: 155, height: 112 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "box box__112x155" },
                          [
                            _c("HeroCommunity", {
                              attrs: { width: 112, height: 155 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "box box__155x112" },
                          [
                            _c("HeroCommunity", {
                              attrs: { width: 155, height: 112 },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "item g2" }, [
                    _c("div", { staticClass: "cta" }, [
                      _c(
                        "h1",
                        {
                          directives: [
                            { name: "twitteremoji", rawName: "v-twitteremoji" },
                          ],
                        },
                        [_vm._v("👋 HI " + _vm._s(_vm.$root.user.firstName))]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.mediaSize === "xl" || _vm.mediaSize === "l"
                    ? _c("div", { staticClass: "item g3" }, [
                        _c(
                          "div",
                          { staticClass: "box box__112x155" },
                          [
                            _c("HeroCommunity", {
                              attrs: { width: 112, height: 155 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "box box__155x112" },
                          [
                            _c("HeroCommunity", {
                              attrs: { width: 155, height: 112 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "box box__112x155" },
                          [
                            _c("HeroCommunity", {
                              attrs: { width: 112, height: 155 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "box box__155x112" },
                          [
                            _c("HeroCommunity", {
                              attrs: { width: 155, height: 112 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "box box__112x155" },
                          [
                            _c("HeroCommunity", {
                              attrs: { width: 112, height: 155 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "box box__155x112" },
                          [
                            _c("HeroCommunity", {
                              attrs: { width: 155, height: 112 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "box box__112x155" },
                          [
                            _c("HeroCommunity", {
                              attrs: { width: 112, height: 155 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "box box__155x112" },
                          [
                            _c("HeroCommunity", {
                              attrs: { width: 155, height: 112 },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.mediaSize === "m" ||
                  _vm.mediaSize === "s" ||
                  _vm.mediaSize === "xs"
                    ? _c("div", { staticClass: "item" }, [
                        _c(
                          "div",
                          { staticClass: "box box__74x103" },
                          [
                            _c("HeroCommunity", {
                              attrs: {
                                width: 74,
                                height: 103,
                                logoDims: 42,
                                reset: true,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "box box__103x74" },
                          [
                            _c("HeroCommunity", {
                              attrs: { width: 103, height: 74, logoDims: 42 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "box box__74x103" },
                          [
                            _c("HeroCommunity", {
                              attrs: { width: 74, height: 103, logoDims: 42 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "box box__103x74" },
                          [
                            _c("HeroCommunity", {
                              attrs: { width: 103, height: 74, logoDims: 42 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "box box__74x103" },
                          [
                            _c("HeroCommunity", {
                              attrs: { width: 74, height: 103, logoDims: 42 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "box box__103x74" },
                          [
                            _c("HeroCommunity", {
                              attrs: { width: 103, height: 74, logoDims: 42 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "box box__74x103" },
                          [
                            _c("HeroCommunity", {
                              attrs: { width: 74, height: 103, logoDims: 42 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "box box__103x74" },
                          [
                            _c("HeroCommunity", {
                              attrs: { width: 103, height: 74, logoDims: 42 },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]
            ),
        _vm._v(" "),
        _vm.$root.user === false
          ? _c("div", { staticClass: "c-popular-communities" }, [
              _c("div", { staticClass: "o-wrapper" }, [
                _c("div", { staticClass: "o-grid" }, [
                  _c("div", { staticClass: "o-grid__item u-width-full" }, [
                    _c("header", {}, [
                      _c("h4", [
                        _vm.$root.user !== false
                          ? _c("a", { attrs: { href: "/hot" } }, [
                              _vm._v("POPULAR COMMUNITIES"),
                            ])
                          : _c("a", { attrs: { href: "/hot" } }, [
                              _vm._v("NEW COMMUNITIES FOR YOU"),
                            ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {},
                      [
                        _c("router-view", {
                          attrs: { name: "hotCommunities" },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "o-wrapper" }, [
          _c("div", { staticClass: "o-grid" }, [
            _c(
              "div",
              { staticClass: "o-grid__item u-width-full" },
              [
                _c("router-view", {
                  attrs: {
                    name: "homeTabs",
                    joinedcommunities: _vm.communityMember,
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("modal"),
      _vm._v(" "),
      _c("preferences-modal", { attrs: { channel: _vm.registration_channel } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", [
      _vm._v("All socials feeds & content,"),
      _c("br"),
      _vm._v("on one platform"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", [
      _vm._v("All socials feeds & content,"),
      _c("br"),
      _vm._v("on one platform"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "search" }, [
      _c("input", {
        attrs: {
          type: "search",
          name: "search",
          id: "search",
          placeholder: "Search what you love...",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }