<template>
    <div class="carousel" data-cy="hot-communities">
        <VueSlickCarousel v-bind="slickSettings">
            <div
                class="c-communities__tile c-communities__tile--hot"
                v-for="item in communities"
                v-bind:key="item.id"
            >
                <a href="#join" class="c-communities__tile-join" @click.prevent="showJoinPanel(item.name)">Join</a>
                <a :href="'/' + item.url">
                    <img
                        v-if="item.imgUrl"
                        class="logo"
                        :src="
                            $simpleStore.cloudinaryUrl(
                                132,
                                132,
                                'img/communities/' +
                                    item.communityId +
                                    '/' +
                                    item.imgUrl,
                                $root
                            )
                        "
                        alt=""
                        width="132"
                        height="132"
                    />
                    <div class="name">{{ item.name }}</div>
                </a>
            </div>
        </VueSlickCarousel>
        <a class="c-btn new u-m-t4 u-m-b2 u-text-small" href="/hot"
            >Explore more</a
        >
    </div>
</template>

<style>
.slick-prev:before,
.slick-next:before {
    color: black;
}
@media only screen and (max-width: 1020px) {
    .custom-arrow.slick-prev {
        left: -20px;
    }
    .custom-arrow.slick-next {
        right: -20px;
    }
}
</style>
<style scoped>
.slick-slide img {
    display: inline !important;
}
</style>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
    components: {
        VueSlickCarousel,
    },
    data() {
        return {
            communities: this.$root.hotCommunities,
            slickSettings: {
                arrows: false,
                autoplay: false,
                draggable: true,
                infinite: true,
                lazyLoad: "ondemand",
                slidesToShow: 8,
                slidesToScroll: 4,
                responsive: [
                    {
                        breakpoint: 1160,
                        settings: {
                            arrows: false,
                            slidesToShow: 8,
                            slidesToScroll: 4,
                        },
                    },
                    {
                        breakpoint: 980,
                        settings: {
                            arrows: false,
                            slidesToShow: 7,
                            slidesToScroll: 7,
                        },
                    },
                    {
                        breakpoint: 800,
                        settings: {
                            arrows: false,
                            slidesToShow: 6,
                            slidesToScroll: 6,
                        },
                    },
                    {
                        breakpoint: 700,
                        settings: {
                            arrows: false,
                            slidesToShow: 5,
                            slidesToScroll: 5,
                        },
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            arrows: false,
                            slidesToShow: 4,
                            slidesToScroll: 4,
                        },
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            arrows: false,
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        },
                    },
                ],
            },
        };
    },
    computed: {},
    methods: {
        showJoinPanel(communityName) {
            this.$simpleStore.showMemberPanel(this, communityName);
        }
    }
};
</script>
